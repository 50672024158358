import * as React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import { MdOutlineError } from "react-icons/md"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div>
        <Container footer colour="0d1120" title="Oops! 404!">
          <MdOutlineError className="text-white text-6xl mb-4" />
          <p className="max-w-prose text-gray-200">Something went wrong! Use the menu to try again!</p>

          <span className="hidden from-purple-900 to-purple-500 from-green-900 to-green-500 from-yellow-900 to-yellow-500 from-ink-900 to-ink-500"></span>

          <span className="hidden to-purple-900 from-purple-500 to-green-900 from-green-500 to-yellow-900 from-yellow-500 to-ink-900 from-ink-500"></span>

          <span className="hidden hover:text-purple-900 hover:text-green-900 hover:text-yellow-900 hover:text-ink-900"></span>
        </Container>
      </div>
    </Layout>
  )
}

export default NotFoundPage
